import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import header from '../images/CleanAir-Desktop-Header-1920x340-Curve-Disclaimer-2x.png'
import headerMobile from '../images/CleanAir-Mobile-Header-1080x550-Curve-Disclaimer-2x.png'
import people from '../images/people.png'

import { Container, Row, Col } from 'reactstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons'

class ThankYouIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={'Thank you! | ' + siteTitle}>
            <meta name="robots" content="noindex" />
          </Helmet>
          <div className="page-banner page-banner--disclaimer">
            <img className="d-none d-lg-block" src={header} alt="" />
            <img className="d-block d-lg-none" src={headerMobile} alt="" />{' '}
            <h1>Thank you!</h1>
          </div>
          <Container className="mt-5 mb-5 pb-5 thank-you-container">
            <Row>
              <Col sm={12} lg={{ size: 6 }} className="">
                <h2>
                  Thank you for completing the questionnaire! Your response has
                  now been submitted.
                </h2>
              </Col>
              <Col sm={12} lg={6} className="pt-3 pt-lg-0">
                <p>
                  Did you know about the proposed Greater Manchester Minimum
                  Licensing Standards for taxi and private hire services?
                </p>
                <p>
                  Please go to{' '}
                  <a target="_blank" href="https://GMTaxistandards.com">
                    GMTaxistandards.com
                  </a>{' '}
                  to view the proposals and respond to the consultation.{' '}
                </p>
                {/* <p>If you would like further details of the Greater Manchester Clean Air Plan, please go to <a href="https://cleanairgm.com">cleanairgm.com</a></p> */}
                <p className="text-center text-lg-left pt-3 pt-lg-0">
                  <a
                    class="btn thank-btn-1 mr-lg-3 mb-3 mb-lg-0"
                    target="_blank"
                    href="https://GMTaxistandards.com"
                  >
                    Visit GMTaxistandards.com
                  </a>
                  <a class="btn thank-btn-2" href="/">
                    Return to Clean Air GM
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default ThankYouIndex

export const pageQuery = graphql`
  query ThankYouQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
